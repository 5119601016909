import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import AddIcon from '@material-ui/icons/Add';
import Dialog from 'components/Common/Dialog';
import Link from 'components/Common/Link';
import { getResources, getResourceMetadata } from 'store/modules/resources';
import Tooltip from '@material-ui/core/Tooltip';
import ResourcesTable from '../ResourcesTable';

export const ResourcesSelectionPropTypes = {};

const getName = (filename) => {
  const names = filename.split('/');
  if (names[names.length - 1] === '') names.pop();
  return names[names.length - 1];
};

const getInputPath = (metadata, inputPath, resource) => {
  if (!resource) return '';
  const safeInputPath = inputPath.replace(/\+/g, ' ');
  const resourcePath = resource ? `${resource.folderPath}${resource.filename}` : '';
  const metadataPath = metadata[safeInputPath]
    ? `${metadata[safeInputPath].folderPath}/${metadata[safeInputPath].filename}`
    : '';
  const resourceInputPath = resourcePath || metadataPath;
  return resourceInputPath;
};

function ResourcesSelection({ inputPath = '', onChange, className, validate = false, rootFolder = '' }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [folder, setFolder] = useState();
  const [folders, setFolders] = useState([]);
  const [selectedResource, setSelectedResource] = useState();
  const resources = useSelector((state) => state.resources.items);
  const loading = useSelector((state) => state.resources.loading);
  const metadata = useSelector((state) => state.resources.metadata);

  const handleOpenDialog = () => setOpen(true);

  const handleCloseDialog = () => setOpen(false);

  const handleConfirm = () => {
    onChange(selectedResource?.s3Path || '');
    handleCloseDialog();
  };

  const handleRowClick = (resource) => () => {
    const { resourceType, s3Path, id } = resource;
    if (resourceType === 'Folder') {
      setFolder(s3Path);
      return setSelectedResource();
    }
    //unselect resource
    if (selectedResource?.id === id) setSelectedResource();
    else setSelectedResource(resource);
  };

  const handleClick = (folder) => () => {
    setFolder(folder);
  };

  useEffect(() => {
    if (open) {
      dispatch(getResources({ folder }));
    }
  }, [folder, open]);

  useEffect(() => {
    //inputPath has precedence over root folder
    if (inputPath) {
      if (metadata[inputPath]) {
        const resource = metadata[inputPath];
        setFolder(resource.folderPath);
        setSelectedResource(resource);
        return;
      }
      dispatch(getResourceMetadata({ inputPath }));
      return;
    }
    if (rootFolder) {
      if (metadata[rootFolder]) {
        const resource = metadata[rootFolder];
        setFolder(resource.folderPath);
        return;
      }
      dispatch(getResourceMetadata({ rootFolder }));
    }
  }, [inputPath, rootFolder, metadata]);

  useEffect(() => {
    if (!folder) setFolders([]);
    else {
      const paths = folder.split('/');
      if (paths[paths.length - 1] === '') paths.pop();
      const folders = [];
      paths.forEach((o, index) => {
        if (index === 0) folders.push(`${o}/`);
        else folders.push(`${folders[folders.length - 1]}${o}/`);
      });
      setFolders(folders);
    }
  }, [folder]);

  const resourceInputPath = getInputPath(metadata, inputPath, selectedResource);
  const filename = resourceInputPath && resourceInputPath.split('/').slice(-1);
  const isValid = !!filename;
  return (
    <div className={className}>
      <Tooltip title={filename || ''} arrow>
        <Input
          error={validate && !isValid}
          id="resource"
          type="text"
          style={{ flex: 1 }}
          value={filename}
          disabled
          endAdornment={
            <InputAdornment position="end">
              <IconButton aria-label="open select resource dialog" onClick={handleOpenDialog}>
                <AddIcon />
              </IconButton>
            </InputAdornment>
          }
        />
      </Tooltip>
      <Dialog
        open={!!open}
        title="Select a Resource"
        onConfirm={handleConfirm}
        onClose={handleCloseDialog}
        onCancel={handleCloseDialog}
      >
        <div>
          <Breadcrumbs aria-label="breadcrumb">
            <Link onClick={handleClick()} href="">
              root
            </Link>
            {folders.map((o, index) => {
              if (index === folders.length - 1) return <Typography key={o}>{getName(o)}</Typography>;
              return (
                <Link key={o} href="" onClick={handleClick(o)}>
                  {getName(o)}
                </Link>
              );
            })}
          </Breadcrumbs>
          <ResourcesTable
            resources={resources}
            loading={loading}
            onClick={handleRowClick}
            handleChecked={handleRowClick}
            selectedResources={selectedResource ? [selectedResource.id] : []}
          />
        </div>
      </Dialog>
    </div>
  );
}
ResourcesSelection.propTypes = ResourcesSelectionPropTypes;

ResourcesSelection.defaultProps = {};

export default ResourcesSelection;
