import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import ResourceSelection from 'components/Database/ResourcesSelection';
import { makeStyles } from '@material-ui/styles';
import { validate } from '@material-ui/pickers';

const classes = makeStyles((theme) => ({
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(1)
  },
  file: {
    minWidth: '25px'
  },
  seismic_name: {
    minWidth: 45
  },
  remove: {
    width: 5
  },
  cellInput: {
    flex: 1,
    display: 'flex'
  }
}));

export const RowPropTypes = {
  row: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
};

function Row({
  row,
  handleFileNameChange,
  index,
  handleFilePathChange,
  total,
  handleDelete,
  validate = false,
  rootFolder
}) {
  const isValidName = !!row.seismic_name;
  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell className={classes.seismic_name}>
          <TextField
            id="seismic_name"
            margin="dense"
            onChange={handleFileNameChange(index)}
            value={row.seismic_name}
            error={validate && !isValidName}
            helperText={validate && !isValidName ? 'Required Field' : ''}
          />
        </TableCell>
        <TableCell className={classes.file}>
          <ResourceSelection
            className={classes.cellInput}
            inputPath={row.file}
            rootFolder={rootFolder}
            onChange={handleFilePathChange(index)}
            validate={validate}
          />
        </TableCell>
        <TableCell className={classes.remove}>
          <IconButton size="small" aria-label="remove" disabled={total <= 1} onClick={handleDelete(index)}>
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
}

export const TableProps = {
  seismic_files: PropTypes.array.isRequired,
  handleFileNameChange: PropTypes.func.isRequired,
  handleFilePathChange: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired
};
const SeismicFilesTable = ({
  seismic_files,
  handleFileNameChange,
  handleFilePathChange,
  handleDelete,
  validate = false
}) => {
  // here we take input from the last row selected by the user to set folders for the resources selection
  //preventing users from the need to go all the way from the root file
  const rootFolder = seismic_files.reduce((rf, current) => {
    if (current.file) return current.file;
    return rf;
  }, '');
  return (
    <TableContainer component="div">
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Seismic Name</TableCell>
            <TableCell>File</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {seismic_files.map((row, index) => (
            <Row
              key={index}
              row={row}
              index={index}
              handleFileNameChange={handleFileNameChange}
              handleFilePathChange={handleFilePathChange}
              handleDelete={handleDelete}
              total={seismic_files.length}
              validate={validate}
              rootFolder={rootFolder}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
SeismicFilesTable.propTypes = TableProps;
export default SeismicFilesTable;
