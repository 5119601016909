import {
  NODE_CATEGORY_RESOURCES,
  NODE_CATEGORY_DATA_VISUALIZATION,
  NODE_CATEGORY_WELL_CONDITIONING,
  NODE_CATEGORY_FEATURE_ENGINEERING,
  NODE_CATEGORY_MODEL_UTILIZATION,
  NODE_CATEGORY_MODEL_DEVELOPMENT,
  NODE_TYPES
} from './constants';

const nodeTypeList = [
  {
    id: NODE_TYPES.COMPILE_WELL_BASE,
    category: NODE_CATEGORY_RESOURCES,
    title: 'Compile Well Base',
    description: 'Combine multiple processed wells into a single data table for feature engineering and model training.'
  },
  {
    id: NODE_TYPES.CREATE_DATA_MODEL,
    category: NODE_CATEGORY_MODEL_DEVELOPMENT,
    title: 'Create Data Model',
    description: 'Define a data model. Data models are characterized by their input data and target properties.'
  },
  {
    id: NODE_TYPES.CREATE_HORIZON,
    category: NODE_CATEGORY_RESOURCES,
    title: 'Import Horizon',
    description:
      'Import a bulk horizon file. A minimum of two horizons are required for the QEarth workflow. We currently support Hampson-Russel, Opendtect, and standard column-wise text files'
  },
  {
    id: NODE_TYPES.CREATE_SEISMIC,
    category: NODE_CATEGORY_RESOURCES,
    title: 'Import Seismic',
    description:
      'Import a post-stack seismic attribute in .sgy format. File headers are expected in SEGY Rev.1 format, but IL/XL X/Y byte locations can be specified for non-standard files.'
  },
  {
    id: NODE_TYPES.BUlK_CREATE_SEISMIC,
    category: NODE_CATEGORY_RESOURCES,
    title: 'Bulk Import Seismic',
    description:
      'Bulk Import post-stack seismic attributes in .sgy format. File headers are expected in SEGY Rev.1 format, but IL/XL X/Y byte locations can be specified for non-standard files.'
  },
  {
    id: NODE_TYPES.ENSEMBLE_SIMULATIONS,
    category: NODE_CATEGORY_MODEL_UTILIZATION,
    title: 'Ensemble Simulations',
    description:
      'Users can pipe various models into this node to perform “bagging” (bootstrap aggregation” and create a model ensemble. As a complimentary QA/QC deliverable, users can generate a confidence indicator attribute that can be exported in segy format. This confidence indicator calculates the variance between the simulations of each model, in the aggregated ensemble, at any given time/depth on an attribute-by-attribute basis.'
  },
  {
    id: NODE_TYPES.ENSEMBLE_WELL_OVERLAY,
    category: NODE_CATEGORY_MODEL_UTILIZATION,
    title: 'Ensemble Well Overlay',
    description:
      'Like the “Ensemble Simulations” node, users can pipe various models into this node to perform “bagging” (bootstrap aggregation” and create a model ensemble. This node produces visuals of well overlays to compare the results of the ensemble to the logged attribute.'
  },
  {
    id: NODE_TYPES.EXPORT_SIMULATIONS,
    category: NODE_CATEGORY_MODEL_UTILIZATION,
    title: 'Export Simulations',
    description:
      'Generates an exportable SEGY format file and gives you the option download it straight to your browser.'
  },
  {
    id: NODE_TYPES.SIMULATE_WELLS,
    category: NODE_CATEGORY_MODEL_UTILIZATION,
    title: 'Simulate Wells',
    description:
      'Simulate a trained model on its original well data for performance review. Provides log overlays per well.'
  },
  {
    id: NODE_TYPES.HYPER_PARAMETER_EXPLORER,
    category: NODE_CATEGORY_MODEL_DEVELOPMENT,
    title: 'Hyperparameter Explorer',
    description:
      'Explores various permutations within hyperparameter space, returning early performance results to guide model design.'
  },
  {
    id: NODE_TYPES.SENSITIVITY_ANALYSIS,
    category: NODE_CATEGORY_MODEL_UTILIZATION,
    title: 'Sensitivity Analysis',
    description:
      'Users can connect various trained models and perform an interactive analysis of the predictions at each well location. Users can then connect the corresponding model simulations to an ensemble node and generate the desired realization.'
  },
  {
    id: NODE_TYPES.WELL_INGESTION,
    category: NODE_CATEGORY_RESOURCES,
    title: 'Well Ingestion',
    description:
      'A consolidated version of the legacy well processing workflow. This node handles the entire configuration process of loading a well into the QEarth workflow.'
  },
  {
    id: NODE_TYPES.MODEL_TRAINING,
    category: NODE_CATEGORY_MODEL_DEVELOPMENT,
    title: 'Model Training',
    description:
      'A consolidated version of the legacy well processing workflow. This node handles the entire model configuration components of the workflow.'
  },
  {
    id: NODE_TYPES.MULTI_SIMULATE_SEISMIC,
    category: NODE_CATEGORY_MODEL_UTILIZATION,
    title: 'Parallel Seismic Simulation',
    description:
      'This node leverages parallel multi-processing capabilities to significantly boost the simulation speed accross models simultaneously.'
  },
  {
    id: NODE_TYPES.QEARTH_FEATURE_ENGINEERING,
    category: NODE_CATEGORY_FEATURE_ENGINEERING,
    title: 'Feature Engineering',
    description: 'A version of feature engineering that allows you to work with seismic and horizons at the same time.'
  },
  {
    id: NODE_TYPES.QEARTH_FEATURE_ENGINEERING_VISUALIZER,
    category: NODE_CATEGORY_DATA_VISUALIZATION,
    title: 'Feature Engineering Visualizer',
    description: 'Interactively visualize combined seismic, well and horizon inputs in 3D'
  },
  {
    id: NODE_TYPES.BULK_WELL_INGESTION,
    category: NODE_CATEGORY_RESOURCES,
    title: 'Bulk Well Ingestion',
    description: 'A version of well ingestion that allows for multiple wells to be loaded at one time.'
  },
  {
    id: NODE_TYPES.OUTPUT_VISUALIZER,
    category: NODE_CATEGORY_DATA_VISUALIZATION,
    title: 'Output Visualizer',
    description: 'Interactively visualize predicted outputs in 3D'
  }
];

const nodeTypesToDictionary = () => {
  const result = {};
  nodeTypeList.forEach((element) => {
    result[element.id] = element;
  });

  return result;
};

const categoryLabels = {
  [NODE_CATEGORY_RESOURCES]: 'Data Ingestion',
  [NODE_CATEGORY_WELL_CONDITIONING]: 'Well Conditioning',
  [NODE_CATEGORY_DATA_VISUALIZATION]: 'Data Visualization',
  [NODE_CATEGORY_FEATURE_ENGINEERING]: 'Feature Engineering',
  [NODE_CATEGORY_MODEL_DEVELOPMENT]: 'Model Development',
  [NODE_CATEGORY_MODEL_UTILIZATION]: 'Model Utilization'
};

const nodeTypesDictionary = nodeTypesToDictionary();

export default nodeTypeList;
export { nodeTypesDictionary, nodeTypeList, categoryLabels };
