import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import ResourceSelection from 'components/Database/ResourcesSelection';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import { wellTable } from './WellFileTable.styles';

function createData(file, dev_file, td_file, well_name, x, y, kb) {
  return { file, dev_file, td_file, well_name, x, y, kb };
}

function displayRow({
  row,
  handleChange,
  index,
  classes,
  handleResourceChange,
  total,
  handleDelete,
  open,
  setOpen,
  rootFolders
}) {
  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell className={classes.cellCollapse}>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open, index)}>
            {open ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
          </IconButton>
        </TableCell>
        <TableCell className={classes.wellName}>
          <TextField id="well_name" onChange={handleChange('well_name', index)} value={row.well_name} />
        </TableCell>
        <TableCell className={classes.uploadFile}>
          <ResourceSelection
            className={classes.cellInput}
            inputPath={row.file}
            onChange={handleResourceChange('file', index)}
            rootFolder={rootFolders.file}
          />
        </TableCell>
        <TableCell className={classes.remove}>
          <IconButton size="small" aria-label="remove" disabled={total <= 1} onClick={handleDelete(index)}>
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={classes.collapsedTable} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1, marginLeft: '10px' }}>
              <Table size="small" aria-label="details">
                <TableHead>
                  <TableRow>
                    <TableCell>Dev File</TableCell>
                    <TableCell>TD File</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.uploadFile}>
                      <ResourceSelection
                        className={classes.devFileCellInput}
                        inputPath={row.dev_file}
                        onChange={handleResourceChange('dev_file', index)}
                        rootFolder={rootFolders.dev_file}
                      />
                    </TableCell>
                    <TableCell className={classes.uploadFile}>
                      <ResourceSelection
                        className={classes.tdFileCellInput}
                        inputPath={row.td_file}
                        onChange={handleResourceChange('td_file', index)}
                        rootFolder={rootFolders.td_file}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>X-Coordinate</TableCell>
                    <TableCell>Y-Coordinate</TableCell>
                    <TableCell>Kelly-Bushing Elevation</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <TextField id="x" type="number" value={row.x} onChange={handleChange('x', index)} />
                    </TableCell>
                    <TableCell>
                      <TextField id="y" type="number" value={row.y} onChange={handleChange('y', index)} />
                    </TableCell>
                    <TableCell>
                      <TextField id="kb" type="number" value={row.kb} onChange={handleChange('kb', index)} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default function WellFileTable({ wellFiles, onChange, well_type, seismic_domain }) {
  const classes = wellTable({ dev_disabled: well_type === 'Vertical', td_disabled: seismic_domain === 'Depth' });
  const [wellRowsOpen, setWellRowsOpen] = React.useState((wellFiles || []).map(() => false));

  const handleAdd = () => {
    onChange([...wellFiles, createData()]);
  };

  const handleDelete = (index) => () => {
    const filterArray = wellFiles.filter((elem, idx) => index !== idx);
    onChange(filterArray);
  };

  const handleChange = (property, index) => {
    return (event) => {
      wellFiles[index][property] = event.target.value;
      onChange(wellFiles);
    };
  };

  const handleResourceChange = (prop, index) => (value) => {
    wellFiles[index][prop] = value;
    onChange(wellFiles);
  };
  // here we take input from the last row selected by the user to set folders for the resources selection
  //preventing users from the need to go all the way from the root file
  const rootFolders = wellFiles.reduce(
    (rf, current) => {
      if (current.file) rf.file = current.file;
      if (current.dev_file) rf.dev_file = current.dev_file;
      if (current.td_file) rf.td_file = current.td_file;
      return rf;
    },
    { file: '', dev_file: '', td_file: '' }
  );

  return (
    <div>
      <TableContainer component="div">
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Well Name</TableCell>
              <TableCell>Log File</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {wellFiles.map((row, index) => {
              return displayRow({
                row,
                index,
                handleChange,
                classes,
                handleResourceChange,
                total: wellFiles.length,
                handleDelete,
                open: wellRowsOpen[index],
                setOpen: (value, index) =>
                  setWellRowsOpen((previous) => {
                    return { ...previous, [index]: value };
                  }),
                rootFolders: rootFolders
              });
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={classes.actions}>
        <IconButton aria-label="add" onClick={handleAdd}>
          <AddIcon />
        </IconButton>
      </div>
    </div>
  );
}
